<template>
  <div class="user-agreement">
    <div class="user-agreement__head">
      <router-link :to="{ name: 'create-account' }"
        >На страницу регистрации</router-link
      >
    </div>
    <div class="user-agreement__content">
      <h1>Пользовательское соглашение</h1>
      <ol>
        <li>
          Настоящее Соглашение о конфиденциальности персональной информации
          (далее – Соглашение) заключается между «_____________________________»
          (далее- Компания) и любым Пользователем мобильного приложения
          GlucoHelp (далее – «Сервис»). Соглашение действует в отношении всей
          информации, которую Компания может получить о пользователе во время
          использования им Сервиса.
        </li>
        <li>
          Компания со всей серьезностью относится к соблюдению права каждого
          посетителя Сервиса, а также конфиденциальности в отношении
          персональных данных и принимает меры по защите любой информации,
          предоставляемой в режиме «онлайн».
        </li>
        <li>
          В рамках настоящего Соглашения под «персональной информацией
          пользователя» понимаются: A. Персональная информация, предоставленная
          Вами при регистрации (создании учетной записи); B. Электронные данные
          (HTTP-заголовки, IP-адрес, файлы cookie, веб-маяки/пиксельные теги,
          данные об идентификаторе браузера, информация об аппаратном и
          программном обеспечении); C. Дата и время осуществления доступа к
          Сайтам и/или Сервисам; D. Информация о геолокации; E. Иная информация,
          которую пользователь предоставляет о себе самостоятельно при
          заполнении любых онлайн- форм. Обязательная для заполнения информация
          помечена специальным образом. Иная информация предоставляется
          пользователем на его усмотрение.
        </li>
        <li>
          Предоставление пользователем своих персональных данных означает
          безоговорочное согласие пользователя с настоящим Соглашением и
          указанными в нем условиями обработки его персональной информации; в
          случае несогласия с этими условиями пользователь должен воздержаться
          от предоставления своих персональных данных на Сервисе.
        </li>
        <li>
          При обработке персональных данных пользователей Компания
          руководствуется Законом Республики Казахстан от 21 мая 2013 года №
          94-V «О персональных данных и их защите» (далее- Закон).
        </li>
        <li>
          Присоединяясь к настоящему Соглашению и оставляя свои данные на
          Сервисе путем заполнения полей любых онлайн - форм, Пользователь
          подтверждает следующее: A. Все указанные данные принадлежат лично
          Пользователю; B. Признает, что Пользователем внимательно и в полном
          объеме прочитано Соглашение и условия обработки персональных данных
          Пользователя, указываемых в полях онлайн- форм, текст Соглашения и
          условия обработки персональных данных Пользователю понятны; C.
          Пользователь дает согласие на обработку Компанией предоставляемых в
          составе информации персональных данных в целях заключения между
          Пользователем и Компанией настоящего Соглашения, а также его
          последующего исполнения; D. Пользователь соглашается с условиями
          обработки персональных данных без оговорок и ограничений.
        </li>
        <li>
          Пользователь дает свое согласие на обработку его персональных данных,
          а именно совершение действий, предусмотренных статьей 8 Закона и
          подтверждает, что, давая такое согласие, он действует свободно,
          согласно своей воле и в своем интересе.
        </li>
        <li>
          Согласие Пользователя на обработку персональных данных является
          конкретным, информированным и сознательным.
        </li>
        <li>
          Настоящее согласие Пользователя признается исполненным в простой
          письменной форме, на обработку следующих персональных данных: фамилию,
          имя, номер мобильного телефона, адрес электронной почты, город
          проживания.
        </li>
        <li>
          При заполнении форм на Сервисе пользователь предоставляет персональную
          информацию. Компания исходит из того, что пользователь предоставляет
          достоверную и достаточную персональную информацию по вопросам,
          предлагаемым в формах. Ответственность за правильность и достоверность
          вводимых персональных данных Пользователь несет самостоятельно.
        </li>
        <li>
          Пользователь, предоставляет Компании право осуществлять следующие
          действия (операции) с персональными данными: сбор и накопление;
          хранение в течение установленных нормативными документами сроков
          хранения отчетности, но не менее трех лет, с момента даты прекращения
          пользования услуг Пользователем; уточнение (обновление, изменение);
          использование; уничтожение; обезличивание; передача по требованию
          уполномоченных государственных органов третьим лицам, с соблюдением
          мер, обеспечивающих защиту персональных данных от несанкционированного
          доступа.
        </li>
        <li>
          Указанное согласие действует бессрочно с момента предоставления данных
          и может быть отозвано Пользователем путем направления Пользователем
          соответствующего распоряжения в простой письменной форме на адрес
          электронной почты (E-mail) _____________________, и указав при этом
          введённые персональные данные. Администратор Сервиса обязуется
          рассмотреть и ответить на письмо в трехдневный срок с момента его
          получения и предпринять все необходимые меры для безвозвратного
          удаления персональных данных с Сервиса.
        </li>
        <li>
          Компания обязуется не разглашать полученную от пользователя
          информацию. Вне пределов, указанных в настоящем Соглашении, информация
          о пользователях не будет каким-либо образом использована. Доступ к
          таким сведениям имеют только лица, специально уполномоченные на
          выполнение данных работ, и предупрежденные об ответственности за
          случайное или умышленное разглашение, либо несанкционированное
          использование таких сведений.
        </li>
        <li>
          В отношении персональной информации пользователя сохраняется ее
          конфиденциальность.
        </li>
        <li>
          Компания принимает необходимые и достаточные организационные и
          технические меры для защиты персональной информации пользователя от
          неправомерного или случайного доступа, блокирования, копирования,
          распространения, а также от иных неправомерных действий с ней третьих
          лиц.
        </li>
        <li>
          Компания не несет ответственности за использование (как правомерное,
          так и неправомерное) третьими лицами Информации, размещенной
          Пользователем на Сервисе, включая её воспроизведение и
          распространение, осуществленные всеми возможными способами.
        </li>
        <li>
          Компания оставляет за собой исключительное право в одностороннем
          порядке вносить изменения и дополнения в настоящее Соглашение. При
          внесении изменений в актуальной редакции указывается дата последнего
          обновления. Новая редакция Соглашения вступает в силу с момента ее
          размещения, если иное не предусмотрено новой редакцией Соглашения.
        </li>
        <li>
          К настоящему Соглашению и отношениям между Пользователем и Компанией,
          возникающим в связи с применением Соглашения, подлежит применению
          право Республики Казахстан.
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.user-agreement {
  min-height: 100vh;
  padding: 32px;

  &__head {
    display: flex;
    justify-content: flex-end;
    text-decoration: underline;
  }
  &__content {
    h1 {
      font-size: 25px;
      text-align: center;
    }
  }

  a {
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    text-decoration: none;
    font-weight: 600;

    &:hover {
      color: lighten(
        rgba($base-text-color, alpha($base-text-color) * 0.7),
        24%
      );
    }
  }
}
</style>